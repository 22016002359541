@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    @apply font-Montserrat;
  }
  body {
    background: #f1f1f1;
  }
  h1 {
    @apply text-xl font-medium capitalize;
  }
}

.link {
  @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium;
}

.active {
  @apply bg-[#1398C9] text-gray-100;
}
